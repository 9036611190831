@import "../config";
@import "../mediaqueries";

.main-nav {
  $el: ".main-nav";

  max-width: 100%;
  max-height: 127px;
  background: $white;
  position: sticky;
  top: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 9999;
  border-bottom: 1px solid $blue;
  border-bottom-left-radius: $radius;
  border-bottom-right-radius: $radius;
  padding: 4px;

  &__logo {
    display: flex;
    align-items: center;
  }

  &__logo-img {
    width: 20%;
    height: 20%;
    margin: auto;

    @include desktop {
      width: auto;
      height: auto;
      border-bottom-left-radius: $radius;
    }
  }

  &__toggle-checkbox {
    display: none;
  }

  &__toggle-label {
    cursor: pointer;
    top: 10px;
    margin-right: 1em;
    display: flex;
    align-items: center;

    @include desktop {
      display: none;
    }
  }

  &__toggle-icon {
    display: block;
    background-color: $blue;
    border-radius: $radius-toggle-icon;
    height: 3px;
    width: 1.5em;
    position: relative;
    transition: all 0.2s ease-out;
  }

  &__toggle-icon::before {
    content: "";
    display: block;
    background: $blue;
    height: 100%;
    width: 100%;
    border-radius: $radius-toggle-icon;
    position: absolute;
    top: -10px;
    transition: all 0.2s ease-out;
  }

  &__toggle-icon::after {
    content: "";
    display: block;
    background: $blue;
    height: 100%;
    width: 100%;
    border-radius: $radius-toggle-icon;
    position: absolute;
    top: 10px;
    transition: all 0.2s ease-out;
  }

  &__nav {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 100%;
    left: 0;
    display: block;
    z-index: 9999;
    border-top: 1px solid $blue;
    transform-origin: top;
    transform: scale(1, 0);
    transition: transform 400ms ease-in-out;

    @include desktop {
      border-top: none;
      display: flex;
      align-items: center;
      position: relative;
      top: initial;
      width: auto;
      max-width: 100%;
      transform: scale(1, 1);
    }
  }

  &__nav-item {
    position: relative;
    padding: 25px 10px;
    background: $white;
    color: $grey;
    cursor: pointer;
    text-align: center;
    flex: 1;
    opacity: 0;
    transition: opacity 250ms ease-in-out;
    border-bottom: 1px solid $blue;
    font-weight: normal;
    font-size: 24px;

    @include desktop {
      opacity: 1;
      display: inline-block;
      min-width: 150px;
      border-radius: $radius;
      border: none;
      font-size: 16px;
    }

    &:hover,
    &:active {
      background: $blue;
      color: $white;

      i {
        color: $white;
      }
    }
  }

  #{$el}__toggle-checkbox:checked ~ #{$el}__nav {
    transform: scale(1, 1);
  }

  #{$el}__toggle-checkbox:checked ~ #{$el}__nav #{$el}__nav-item {
    opacity: 1;
  }

  #{$el}__toggle-checkbox:checked ~ #{$el}__toggle-label #{$el}__toggle-icon {
    background: transparent;
  }

  #{$el}__toggle-checkbox:checked
    ~ #{$el}__toggle-label
    #{$el}__toggle-icon::before {
    transform: rotate(-45deg);
    top: 0;
  }

  #{$el}__toggle-checkbox:checked
    ~ #{$el}__toggle-label
    #{$el}__toggle-icon::after {
    transform: rotate(45deg);
    top: 0;
  }
}