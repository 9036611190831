@import "../config";
@import "../mediaqueries";

.footer {
  width: 100%;
  background: $white;
  margin-top: 25px;
  border-radius: $radius;

  @include desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 25px;
    max-width: 100%;
    height: 100px;
    margin: 25px auto;
  }

  &__box {
    max-width: 100%;
    text-align: center;
    padding: 5px;
  }

  i {
    font-size: 24px;

    @include desktop {
      font-size: 42px;
    }
  }

  &__link {
    color: $grey;

    &:hover {
      color: $grey;
      text-decoration: underline;
    }
  }
}