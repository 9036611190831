@import "../config";
@import "../mediaqueries";

.photo-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 5px;
  justify-items: center;

  img {
    box-shadow: $shadow;

    &:nth-child(2) {
      grid-column: span 3;
      grid-row: span 2;
    }
  }
}
