@import "../config";
@import "../mediaqueries";

.product-details {
  background: $white;
  box-shadow: $shadow;
  border-radius: $radius;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 32px 12px;

  @include tablet {
    padding: 32px 64px;
  }

  @include desktop {
    padding: 32px 128px;
  }

  &__description {
    padding: 12px 0 12px;
  }

  &__description-subtitle {
    font-weight: bold;
  }

  &__add-button {
    margin-top: 24px;
    margin-bottom: 12px;
    @include tablet {
      margin: 0;
      margin-left: 98px;
    }
  }

  &__prijs {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 20px;

    @include tablet {
      flex-direction: row;
      align-items: center;
      padding: 0;
    }
  }

  img {
    max-height: 500px;
    border-radius: $radius;
    border: none;
  }

  i {
    font-size: 20px;
  }
}
