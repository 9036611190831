@import "./config";
@import "./mediaqueries";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $ff-montserrat;
  font-size: 16px;
  color: $grey;

  &:focus {
    outline-color: $hover;
  }
}

body {
  background: $blue;
}

h1 {
  font-family: $ff-chewy;
  color: $blue;
  font-size: 36px;

  @include tablet {
    font-size: 48px;
  }

  @include desktop {
    font-size: 60px;
  }
}

h2,
h3,
h4,
h5,
h6 {
  font-family: $ff-patrick;
  color: $blue;
}

h2 {
  font-size: 28px;

  @include tablet {
    font-size: 32px;
  }

  @include desktop {
    font-size: 36px;
  }
}

h3 {
  font-size: 24px;
  text-align: center;
}

h4 {
  font-size: 20px;
  margin-bottom: 0px;
  margin-top: 5px;
}

p {
  @include tablet {
    font-size: 18px;
  }
}

a {
  color: $link;
  font-weight: bold;
  text-decoration: none;

  &:hover {
    color: $hover;
  }

  &:active {
    color: $active;
  }
}

img {
  max-width: 100%;
}

.form-button,
.formButton {
  display: inline-block;
  background: $blue;
  border-radius: $radius;
  color: $white;
  cursor: pointer;
  text-align: center;
  border: none;
  margin-top: 24px;
  padding: 12px;
  width: 100%;

  @include tablet {
    max-width: 320px;
    width: auto;
    min-width: 150px;
    margin-top: 0;
  }

  i {
    color: $white;
  }

  &:hover {
    box-shadow: inset 0 0 0 2px $blue;
    background: $white;
    color: $blue;

    i {
      color: $blue;
    }
  }

  &:disabled {
    background: $disabled-bg;
    color: $disabled;
    pointer-events: none;
  }

  &:focus {
    outline-offset: 4px;
  }
}

.page-title {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 25px 0;
  padding: 5px;
  box-shadow: $shadow;
  background: $white;
  border-radius: $radius;
  border: none;
  max-width: 100%;
  position: relative;
  text-align: center;
  flex-direction: column-reverse;
  flex-wrap: wrap;

  @include desktop {
    flex-direction: row;
  }
}

.boxes {
  display: block;

  @include tablet {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}

.back-btn {
  background: $white;
  cursor: pointer;
  display: inline-block;
  border-radius: $radius;
  border: none;
  padding: 10px;

  i {
    margin-right: 4px;
  }

  &:hover {
    background: $blue;
    color: $white;
    i {
      color: $white;
    }
  }

  @include desktop {
    position: absolute;
    left: 5px;
  }
}

.box {
  background: $white;
  box-shadow: $shadow;
  border-radius: $radius;
  border: none;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 10px;
  padding: 32px 12px;

  @include tablet {
    padding: 32px;
  }

  &-photo {
    justify-content: center;
  }

  &__title {
    margin-bottom: 24px;
    text-align: center;
  }

  img {
    max-height: 500px;
    border-radius: $radius;
    border: none;
  }
}

.c-form {
  &-group {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include tablet {
      flex-direction: row;
    }

    &__left {
      flex: 0 0 100%;
      width: 100%;

      @include tablet {
        flex: 0 0 25%;
        margin-right: 32px;
        width: auto;
      }
    }

    &__right {
      flex-basis: 100%;
      width: 100%;
    }
  }

  &-group:not(:last-child) {
    margin-bottom: 32px;
  }
}

.o-input {
  &__field {
    padding: 10px;
    width: 100%;
  }

  &__radio {
    margin-right: 6px;
  }

  &__radio-label {
    display: block;
    padding: 4px;
  }

  &__textarea {
    resize: none;
    width: 100%;
  }
}

.succes,
.sending,
.error {
  border-radius: $radius;
  padding: 25px;
  font-weight: bold;
  margin: 0 0 25px;
}

.error {
  color: $error;
  background-color: $error-bg;
  box-shadow: inset 0 0 0 2px $error;
}

.succes {
  color: $succes;
  box-shadow: inset 0 0 0 2px $succes;
  background-color: $succes-bg;
}

.sending {
  color: $blue;
  background-color: $white;
  box-shadow: inset 0 0 0 2px $blue;
}

#login {
  text-align: center;
}

.load {
  background: $white;
  text-align: center;
  border-radius: $radius;
  padding: 2rem 45%;
  box-shadow: $shadow;
}
