@import "../config";
@import "../mediaqueries";

.c-main-photo {
  display: grid;
  grid-template-areas: "foto foto" "foto foto";
  margin-bottom: 20px; 

  &__img {
    grid-area: foto;
    background: url("https://www.haakdraak.nl/afbeeldingen/48-2.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    padding: 25px;
    min-height: 250px;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: $shadow;

    @include mobile {
      min-height: 300px;
    }

    @media (min-width: 620px) {
      min-height: 375px;
    }

    @include desktop {
      min-height: 512px;
      background-size: cover;
      padding: 3rem;
    }
  }

  &__title {
    margin-bottom: 0;
    color: $white;

    @media (min-width: 620px) {
      font-size: 2.5rem;
    }

    @include desktop {
      font-size: 4rem;
    }
  }

  &__text {
    margin-top: 0;
    color: $white;
    text-align: center;

    @media (min-width: 620px) {
      font-size: 24px;
    }

    @include desktop {
      font-size: 28px;
    }
  }
}