@mixin mobile {
  @media (min-width: #{$mobile}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop}){
    @content;
  }
}