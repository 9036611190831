@import "../config";
@import "../mediaqueries";

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: no-wrap;
  max-width: 100vw;

  &__button {
    display: inline-block;
    background-color: $white;
    color: $grey;
    cursor: pointer;
    text-align: center;
    border: none;
    padding: 12px;
    margin: 2px;
    opacity: 1;

    @include tablet {
      padding: 16px;
    }

    &:hover {
      background: $blue;
      color: $white;
    }

    &:disabled {
      opacity: 0;
    }
  }

  &__button--arrow {
    display: none;
  
    @include desktop {
      display: inline-block;
    }
  }

  .active {
    background-color: lightblue;
    cursor: default;
    font-weight: bold;

    &:hover {
      background: lightblue;
      color: $grey;
    }
  }
}
